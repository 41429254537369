<!--
 * @Author: 羡国柱
 * @Date: 2023-07-18 14:58:13
 * @LastEditors: 邵澳 1398037353@qq.com
 * @LastEditTime: 2023-08-16 17:14:20
 * @FilePath: \src\components\scrollWrapper\SEduArchives\Archives\Sub\LevelTotalScore.vue
 * @Description: 智慧教学 > 综评档案 > 等级设置 > 总分数等级设置
-->
<template>
	<div class="level_total_score-wrap">
		<el-form :inline="true" :model="dynamicTProjectForm" ref="dynamicTProjectForm" size="mini" class="demo-dynamic">
			<div class="demo-dynamic-title">成绩标准</div>
			<div class="scale-wrap" style="display: none;">
				<div class="scale-wrap-info">
					<div class="scale-wrap-info-0">
						<span class="number-text">0</span>
					</div>
					
					<!-- 不合格 -->
					<div class="scale-wrap-info-not" v-for="item in dynamicTProjectForm.domains">
						<div class="scale-wrap-info-line-info" :class="item.isPassLine == '1' ? 'scale-wrap-info-not-info' : ''" 
							:style="{width: item.score + 'px'}">
							<span class="number-text-has" v-if="item.isPassLine == '1'">{{ item.score }}(合格线)</span>
							<span class="number-text-has" v-else>{{ item.score }}</span>
							<span class="number-text-line"></span>
							<span class="number-text-not" 
								v-if="item.isPassLine == '1'" :style="{width: item.score + 'px'}">不合格</span>
							<span class="number-text-noted" :style="{right: (item.score + 30) + 'px'}" :title="item.name">{{ item.name }}</span>
						</div>
					</div>
					
					
					<div class="scale-wrap-info-100">
						<span class="number-text">满分</span>
					</div>
				</div>
			</div>
			
			<div class="scale-addly">
				<div class="scale-addly-title">
					<div class="scale-add-rows_title">等级</div>
					<div class="scale-add-rows_title">
						<span>范围</span>
						<span style="color: #999; font-size: 12px; font-weight: 400;">（成绩：X）</span>
					</div>
					<div class="scale-add-rows_title"></div>
				</div>
				<div class="scale-add-rows" v-for="(item, index) in dynamicTProjectForm.domains" :key="item.id" @mouseenter="isShowEdit(item)" @mouseleave="isHidenEdit">
					<div class="scale-add-rows_cell scale-add-rows_grade"  >
						<el-input v-model="item.name" v-if="inputShowId === item.id" :disabled="item.disabled" @input="namerInfo(item.name, index)"></el-input>
						<div class="scale-add-rows_grade-info" v-else>{{ item.name }}</div>
					</div>
					<div class="scale-add-rows_cell scale-add-rows_scope" style="width: 19%;">
						<div class="setting_row setting_value" style="width: 20%;">X</div>
						<div class="setting_row setting_symbol" style="width: 20%;">≥</div>
						<div class="setting_row setting_number" style="width: 57%;">
							<!-- <el-input v-model="item.score" v-if="inputShowId === item.id" @input="scoreInfo(item.score, index)" :max="999" :min="0"></el-input> -->
							<el-input-number v-model="item.score"  :min="0" :max="999" controls-position="right" :precision="getPrecision" :step="0.1"
								:controls="false" v-if="inputShowId === item.id" @input="scoreInfo(item.score, index)" @blur="setPrecision($event,item)"></el-input-number>
							<span v-else>{{ item.score }}</span>
						</div>
					</div>
					<div class="scale-add-rows_cell scale-add-rows_selete" style="width: 35%;">
						<div v-if="item.scoreRadio && item.scoreRadio == '1' " style="display: flex;">
							<span style="color: #3c7fff; font-size: 14px;">合格线</span>
							<span class="el-icon-del" v-if="inputShowId === item.id && item.name !== '优秀'" style="margin-top: 9px; margin-left: 18px;" @click="removeDomain(item)"></span>
						</div>
						<div v-else>
							<div v-if="inputShowId === item.id" style="display: flex; padding-top: 12px; padding-right: 15px;">
								<el-radio  v-model="item.scoreRadio" label="1" @input="getScoreRadio(item)">
									设置<span style="color: #3c7fff; font-weight: 600;">{{ item.score }}</span>为合格线
								</el-radio>
								<span class="el-icon-del" @click="removeDomain(item)" v-if="item.name !== '优秀'"></span>
							</div>
						</div>
						
					</div>
				</div>
				<div class="scale-add-append">
					<i class="el-icon-plus" @click="addDomain"></i><span @click="addDomain"> 添加等级</span>
				</div>
			</div>
			<div style="height: 64px;"></div>
			<div class="scale-add-save">
				<div class="scale-add-save-btn">
					<el-button type="primary" v-has-permi="['level:save']" @click="saveLevelProject">保存</el-button>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
	
	import { DialogWrapper } from 'common-local'
	import { mapState } from 'vuex';
	
	
	export default {
		name: 'LevelTotalScore',
		components: {
			DialogWrapper
		},
		data() {
			return {
				timer: null,
				inputShowId: '', 
				inputShowScore:'',
				dynamicTProjectForm: {
					domains: [
						{
							schoolId: this.$store.state.schoolId,
							type: "2",
							id: "",
							name: "优秀",
							score: 90,
							scoreRadio: "0",
							isPassLine: "0",
							deleteStatus: 0,
							disabled: true
						}
					]
				}
			}
		},
		created() {
			
			
		},
		mounted() {
			this.handleProjectList();
		},
		computed:{
			getPrecision(){
				let value = this.inputShowScore;
				//没有输入值
				if(!value && value != 0){
					return 0
				}
				let arr = value.toString().split('.')
				//有值没小数
				if(arr.length == 1){
					return 0
				}else{//有值有小数
					return arr[1].length > 2 ? 2 : arr[1].length
				}
			},
		},
		watch:{
		    $route(to,from){
		      //console.log('从哪来', from.path);//从哪来
		     // console.log('到哪去', to.path);//到哪去
			  this.handleProjectList();
		    }
		},
		methods: {
			setPrecision(event,item){
				let num = event.target.value;
				if(num == '' || Number(num).toString() == 'NaN'){
					return
				}
				//有小数点
				if(num.indexOf('.') != -1){
					if(num.split('.')[1].length == 0){//小数点后没数字
						item.score = num.replace('.','')
					}else{
						item.score = num.substring(0,num.indexOf(".") + 3);
					}
				}else{
					item.score = num;
				}
				this.$forceUpdate();
			},
			/**
			 * @Description: 总分等级列表
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-24 09:37:50
			 */
			handleProjectList() {
				let dataList = [];
				this._fet('/school/schoolCerLevel/listByCondition', {
					schoolId: this.$store.state.schoolId,
					type: '2'
				}).then((res) => {
				    if (res.data.code === '200') {
						let reg = res.data.data;
						console.log('总分等级', reg)
						reg.forEach(item => {
							item.schoolId = item.schoolId;
							item.type = item.type;
							item.id = item.id;
							item.name = item.name;
							item.score = item.score;
							item.scoreRadio = item.isPassLine;
							item.isPassLine = item.isPassLine;
							item.deleteStatus = item.deleteStatus;
							if(item.name === '优秀') {
								item.disabled = true
							} else {
								item.disabled = false
							}
						});
						
						if (reg.length > 0) this.dynamicTProjectForm.domains = reg;
				    } else if (res.data && res.data.msg) {
				        this.$message.error(res.data.msg);
				    }
				});
			},
			/**
			 * @Description: 显示选则合格线
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 17:37:22
			 */
			isShowEdit(dataItem) {
				this.inputShowId = dataItem.id
				this.inputShowScore = dataItem.score;
			},
			/**
			 * @Description: 隐藏选则合格线
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 17:37:22
			 */
			isHidenEdit() {
				this.inputShowId = '';
			},
			/**
			 * @Description: 获取合格线
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 17:37:22
			 */
			getScoreRadio(val) {
				let dataList = this.dynamicTProjectForm.domains;
				let dataLength = this.dynamicTProjectForm.domains.length;
				for(let j = 0; j < dataLength; j++) {
					dataList[j].scoreRadio = '';
					dataList[j].isPassLine = '0';
					if(val.id === dataList[j].id) {
						dataList[j].scoreRadio = '1';
						dataList[j].isPassLine = '1';
					}
				}
			},
			/**
			 * @Description: 判断当前设置等级名称值
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-24 10:53:33
			 */
			namerInfo(val, index) {
				return false;
				let that = this;
				let dataList = this.dynamicTProjectForm.domains;
				let dataLength = this.dynamicTProjectForm.domains.length;
				clearTimeout(that.timer)
				
				that.timer = setTimeout(function () {
					if(val) {
						for(let k = 0; k < dataLength; k++) {
							console.log(val)
							console.log(dataList[k].name)
							console.log(val === dataList[k].name)
							
							if(val === dataList[k].name) {
								that.$alert('当前等级设置的名称与其他等级有重复，请重新输入。', '提示', {
									confirmButtonText: '确定',
									type: 'warning',
									showClose: false,
									center: true
								});
							}
						}
					}
				}, 900);
			},
			/**
			 * @Description: 判断当前设置分数值的大小
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-24 10:53:45
			 */
			scoreInfo(val, index) {
				// console.log(index)
				
				return false;
				let that = this;
				let dataList = this.dynamicTProjectForm.domains;
				let dataLength = this.dynamicTProjectForm.domains.length;
				clearTimeout(that.timer)
				that.timer = setTimeout(function () {
					if(val) {
						val = val - 0;
						let  currentBList = dataList.slice(0, index);
						let  currentAList = dataList.slice(index + 1, dataList.length);
						// console.log(currentAList)
						if(index > 0) {
							for(let j = 0; j < currentBList.length; j++) {
								if(val >= currentBList[j].score) {
									that.$alert('当前等级设置分值不可大于或等于上等级分值', '提示', {
										confirmButtonText: '确定',
										type: 'warning',
										showClose: false,
										center: true
									});
								}
							}
						}
						
						for(let j = 0; j < currentAList.length; j++) {
							if(val <= currentAList[j].score) {
								// console.log(currentAList[j].score)
								that.$alert('当前等级设置分值不可小于等于下等级分值', '提示', {
									confirmButtonText: '确定',
									type: 'warning',
									showClose: false,
									center: true
								});
							}
						}
					}
				}, 900);
			},
			/**
			 * @Description: 新增等级
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 17:37:22
			 */
			addDomain() {
				this.dynamicTProjectForm.domains.push({
					id: Date.now(),
					schoolId: this.$store.state.schoolId,
					type: '2',
					score: '', 
					name: '',
					scoreRadio: '',
					isPassLine: '0',
					deleteStatus: 0,
					disabled: false
				});
			},
			/**
			 * @Description: 删除等级
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 17:37:22
			 */
			removeDomain(item) {
				let index = this.dynamicTProjectForm.domains.indexOf(item)
				if (index !== -1) {
				  this.dynamicTProjectForm.domains.splice(index, 1)
				}
			},
			/**
			 * @Description: 保存
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 06:30:39
			 */
			saveLevelProject() {
				let listData = this.dynamicTProjectForm.domains;
				//判断有没有分数为空的
				if(listData.filter(item => [undefined,null,''].includes(item.score)).length > 0){
					this.$message.warning('总分等级分数不能为空');
					return false
				}
				let listLength = listData.length;
				let newListLength = new Set(listData.map(item=>item.name)).size;
				let max = null;
				let maxIndex = 0;
				let scoreList = [];
				let maxState = false;
				let minState = false;
				
				for(let j = 0; j < listData.length; j++) {
					if(listData[j].name === '') {
						this.$message.warning('请设置总分等级名称');
						return false;
					}
					if(listData[j].score === '') {
						this.$message.warning('请设置总分等级分数');
						return false;
					}
				}
				
				if(listLength > newListLength) {
					this.$message.warning('设置总分等级名称有重复内容');
					return false;
				}
				
				for(let j = 0; j < listLength; j++) {
					scoreList.push(listData[j].score - 0);
				}
				// 分值最大值
				max = Math.max.apply(null, scoreList);
				maxIndex = scoreList.findIndex(value=>value == max)
				let  currentBList = listData.slice(0, maxIndex);
				
				for(let j = 0; j < scoreList.length; j++) {
					if(scoreList[j + 1] && scoreList[j + 1] > scoreList[j]) {
						console.log('scoreList[j]', scoreList[j])
						minState = true;
					}
				}
				//console.log('scoreList数组 ', scoreList)
				// let min = scoreList[0];
				// let second;
				// for (var i = 1; i < scoreList.length; i++) {
				//      if (min > scoreList[i]) {
				//         second = min
				//         min = scoreList[i];
				//      } else if(scoreList[i]<second&&scoreList[i]!=min){  
				// 		//更新比最小值小，比第二小值大的
				//         second = scoreList[i];
				//      }
				// }
				
				if(currentBList.length > 0) {
					maxState = true;
				}
				// console.log(currentBList)
				
				if(maxState && minState) {
					console.log('1')
					this.$message.warning('设置总分等级分值不符合大小排序规则，请重新设置。');
					return false;
				} else if(maxState) {
					this.$message.warning('设置总分等级分值不符合大小排序规则，请重新设置。');
					console.log('2')
					return false;
				} else if(minState) {
					this.$message.warning('设置总分等级分值不符合大小排序规则，请重新设置。');
					console.log('3')
					return false;
				}
				
				//判断一下有没有设置合格线
				if(listData.filter(item => item.isPassLine == 1).length == 0){
					this.$message.error('总分等级设置中需要设置合格线');
					return false;
				}

				this._fet('/school/schoolCerLevel/batchSave', {
					list: listData
				}).then((res) => {
				    if (res.data.code === '200') {
						this.$message.success('保存成功');
						this.handleProjectList();
				    } else if (res.data && res.data.msg) {
				        this.$message.error(res.data.msg);
				    }
				});
			}
		}
	}
	
</script>

<style>
	.level_total_score-wrap {
		width: 636px;
		margin-top: 10px;
		/* border: 1px solid #e2e2e2; */
		border-radius: 10px;
		box-shadow: 0 0 10px #f0f0f0;
	}
	
	.level_total_score-wrap .demo-dynamic {
		position: relative;
		height: 99.9%;
	}
	
	.level_total_score-wrap .demo-dynamic-title {
		color:#333; 
		font-size: 14px; 
		font-weight: 600; 
		text-align: center;
		padding-top: 15px; 
		padding-bottom: 44px;
	}
	
	
	
	/* 刻度尺 */
	.level_total_score-wrap .scale-wrap .scale-wrap-info{
		width: 560px;
		height: 30px;
		cursor: pointer;
		margin: 0 auto 20px;
		background-color: #3C7FFF;
		position: relative;
	}
	
	.level_total_score-wrap .scale-wrap .scale-wrap-info-0 {
		position: absolute;
		top: -7px;
		right: 0;
		width: 1px;
		height: 45px;
		background-color: #c0c0c0;
	}
	
	.level_total_score-wrap .scale-wrap .scale-wrap-info-0 .number-text {
		position: absolute;
		top: -18px;
		right: -3px;
		color: #333;
		font-size: 12px;
	}
	
	.level_total_score-wrap .scale-wrap .scale-wrap-info-100 {
		position: absolute;
		top: -7px;
		left: 0;
		width: 26px;
	}
	
	.level_total_score-wrap .scale-wrap .scale-wrap-info-100 .number-text {
		position: absolute;
		top: -18px;
		left: -3px;
		color: #333;
		font-size: 12px;
	}
	
	/* 不合格 */
	.level_total_score-wrap .scale-wrap .scale-wrap-info-not {
		position: absolute;
		top: 0;
		right: 0;
	}
	
	.level_total_score-wrap .scale-wrap-info-line-info {
		width: 0;
		height: 30px;
		position: relative;
	}
	
	.level_total_score-wrap .scale-wrap-info-not-info {
		background-color: #81acff;
	}
	
	.level_total_score-wrap .scale-wrap .scale-wrap-info-not .number-text-has {
		position: absolute;
		top: -26px;
		left: -20px;
		color: #3c7fff;
		font-size: 12px;
	}
	
	.level_total_score-wrap .scale-wrap .scale-wrap-info-not .number-text-line {
		position: absolute;
		top: -7px;
		left: 0;
		width: 1px;
		height: 45px;
		background-color: #c0c0c0;
	}
	
	.level_total_score-wrap .scale-wrap .scale-wrap-info-not .number-text-not {
		position: absolute;
		bottom: -22px;
		right: 0;
		width: 62px;
		color: #333;
		text-align: center;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.level_total_score-wrap .scale-wrap .scale-wrap-info-not .number-text-noted {
		position: absolute;
		bottom: -22px;
		right: 0;
		width: 62px;
		color: #333;
		text-align: center;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	
	/* 新增等级区域 */
	.level_total_score-wrap .scale-addly {
		height: 295px;
		overflow-y: auto;
	}
	
	.level_total_score-wrap .scale-addly-title {
		width: 540px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}
	
	.level_total_score-wrap .scale-addly-title .scale-add-rows_title {
		width: 32%;
		color: #333;
		font-size: 14px;
		font-weight: 600;
		text-align: center;
	}
	
	.level_total_score-wrap .scale-add-rows_grade {
		color: #333;
		font-size: 14px;
		text-align: center;
	}
	
	.level_total_score-wrap .scale-add-rows_grade .el-input {
		width: 92.8px;
		
	}
	
	.level_total_score-wrap .scale-add-rows_grade .el-input .el-input__inner {
		height: 28px;
	}
	
	.level_total_score-wrap .scale-add-rows_grade-info {
		width: 61px;
		height: 36px;
		margin: 0 auto;
		padding-bottom: 8px;
		box-sizing: border-box;
		border-bottom: 1px solid #747474;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	
	.level_total_score-wrap .scale-add-append {
		width: 540px;
		height: 36px;
		margin: 21px auto;
		color: #3c7fff;
		font-size: 14px;
		padding-left: 86px;
		cursor: pointer;
	}
	
	.level_total_score-wrap .scale-add-append .el-icon-plus {
		font-weight: 600;
	}
	
	.level_total_score-wrap .scale-add-rows {
		width: 540px;
		height: 36px;
		line-height: 36px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		margin-top: 12px;
		box-sizing: border-box;
		
	}
	
	.level_total_score-wrap .scale-add-rows:hover {
		background-color: #e2e2e2;
	}
	
	.level_total_score-wrap .scale-add-rows_cell {
		width: 32%;
	}
	
	.level_total_score-wrap .scale-add-rows_scope {
		display: flex;
		
	}
	
	.level_total_score-wrap .scale-add-rows_scope .setting_row {
		width: 32%;
		text-align: center;
	}
	
	.level_total_score-wrap .scale-add-rows_scope .setting_value {
		color: #333;
		font-size: 16px;
	}
	
	.level_total_score-wrap .scale-add-rows_scope .setting_symbol {
		color: #333;
		font-size: 14px;
	}
	
	.level_total_score-wrap .scale-add-rows_scope .setting_number {
		height: 36px;
		color: #333;
		font-size: 14px;
	}
	
	.level_total_score-wrap .scale-add-rows_scope .setting_number .el-input {
		
	}
	
	.level_total_score-wrap .scale-add-rows_scope .setting_number .el-input .el-input__inner {
		height: 28px;
	}
	
	.level_total_score-wrap .scale-add-rows_selete .el-radio {
		width: 130px;
	}
	
	.level_total_score-wrap .scale-add-rows_selete .el-radio__label {
		padding-left: 0 !important;
	}
	
	.level_total_score-wrap .scale-add-rows_selete .el-icon-del {
		width: 17px;
		height: 17px;
		background-image: url('../../../../assets/images/record_del.png');
		background-repeat: no-repeat;
		background-size: 100%;
	}
	
	.level_total_score-wrap .el-input-number {
		width: auto !important;
	}
	
	.level_total_score-wrap .scale-add-rows_scope .setting_number .el-input .el-input__inner {
	    height: 28px;
	    padding: 0 8px;
	}
	
	/* 保存 */
	.level_total_score-wrap .scale-add-save {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
	}
	
	.level_total_score-wrap .scale-add-save .scale-add-save-btn {
		width: 100%;
		text-align: right;
		padding-right: 23px;
		padding-bottom: 18px;
		box-sizing: border-box;
	}
</style>